import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import { Canvas, UpdateReasons } from 'cvat-canvas-wrapper';
import { ShapeType, ObjectType, ObjectState } from 'cvat-core-wrapper';
import {
    findCenterOfRectangle,
    getPointValue,
    getShapeFromStates,
    MMSI_KEY,
    CONNECTOR_KEY,
    getStateObject,
    getConnecterShape,
    copyAttributeValue,
    getMmsiValueFromShape,
} from 'utils/association-helper';
import { CombinedState } from 'reducers';
import {
    updateAnnotationsAsync,
    createAnnotationsAsync,
    removeObject as removeObjectAction,
} from 'actions/annotation-actions';

interface StateToProps {
    canvasInstance: Canvas | null;
    frame: number;
    frameWasFetching: boolean;
    jobInstance: any;
    states: ObjectState[];
    activatedStateID: number | null;
}

interface DispatchToProps {
    onUpdateAnnotations(states: ObjectState[]): void;
    onCreateAnnotations(states: ObjectState[]): void;
    removeObject(objectState: ObjectState): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
            player: {
                frame: { number: frame, fetching: frameWasFetching },
            },
            annotations: { states, activatedStateID },
        },
    } = state;

    return {
        canvasInstance,
        jobInstance,
        states,
        activatedStateID,
        frame,
        frameWasFetching,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onUpdateAnnotations(states: ObjectState[]): void {
            dispatch(updateAnnotationsAsync(states));
        },
        onCreateAnnotations(states: ObjectState[]): void {
            dispatch(createAnnotationsAsync(states));
        },
        removeObject(objectState: ObjectState): void {
            dispatch(removeObjectAction(objectState, false));
        },
    };
}

function AssociationButton(props: StateToProps & DispatchToProps): JSX.Element | null {
    const {
        canvasInstance,
        onUpdateAnnotations,
        jobInstance,
        removeObject,
        states,
        frame,
        frameWasFetching,
        onCreateAnnotations,
    } = props;

    // update rectangle annotation
    const updateRectangleAnnotation = (state: ObjectState, value: string, key: string): void => {
        const rectAttributes = state?.label?.attributes ?? [];
        const attr = copyAttributeValue(rectAttributes, key, value);
        state.attributes = attr;

        onUpdateAnnotations([state]);
    };

    const resetSelection = (): void => {
        canvasInstance?.notify(UpdateReasons.ASSOCIATION_SHAPE_DONE);
    };

    /**
     * Create annotation for the new polyline "connector"
     */
    const createAnnotation = (state: ObjectState, frameToCreateAnnotation: number): void => {
        const { selectedPoint } = canvasInstance?.selectedShapeState ?? {};
        const objectState = getStateObject(state, frameToCreateAnnotation, selectedPoint);
        onCreateAnnotations([objectState]);
    };

    /**
     * Polyline "connector" has to go from the center of the selected
     * Rectangle to the selected Point (AIS) location.
     */
    const drawPolylineConnector = (selectedRect: ObjectState, selectedPoint: ObjectState): void => {
        const connectorLabel = jobInstance.labels?.find((x: { name: string }) => x.name === CONNECTOR_KEY);
        if (connectorLabel) {
            const selectedPoints = selectedPoint?.points ?? [];
            const { centerRectX, centerRectY } = findCenterOfRectangle(selectedRect?.points ?? []);
            const pointMmsiValue = getPointValue(selectedPoint, MMSI_KEY);
            const attributes = copyAttributeValue(connectorLabel.attributes ?? [], MMSI_KEY, pointMmsiValue);
            const objectType = selectedRect.objectType === ObjectType.TRACK ? ObjectType.TRACK : ObjectType.SHAPE;
            const data = {
                shapeType: ShapeType.POLYLINE,
                objectType,
                points: [centerRectX, centerRectY, selectedPoints[0], selectedPoints[1]],
                attributes,
            };
            const firstFrame = 0;
            const frameToCreateAnnotation = selectedRect.objectType === ObjectType.TRACK ? firstFrame : frame;
            createAnnotation(
                {
                    ...data,
                    label: connectorLabel,
                    zOrder: 0,
                } as ObjectState,
                frameToCreateAnnotation,
            );
        }
    };

    /**
     * Update the points of an existing polyline "connector".
     */
    const updateConnectorPoints = (
        connector: ObjectState,
        selectedRect: ObjectState,
        selectedPoint: ObjectState,
    ): void => {
        const selectedPoints = selectedPoint?.points ?? [];
        const { centerRectX, centerRectY } = findCenterOfRectangle(selectedRect?.points ?? []);
        connector.points = [centerRectX, centerRectY, selectedPoints[0], selectedPoints[1]];
        onUpdateAnnotations([connector]);
    };

    /**
     * Update the points of an existing polyline "connector".
     */
    const updateConnectorHidden = (connector: ObjectState, hidden: boolean): void => {
        connector.hidden = hidden;
        onUpdateAnnotations([connector]);
    };

    /**
     * Update all connectors when the frame changes.
     */
    const updateAllConnectors = (): void => {
        const connectorLabel = jobInstance.labels?.find((x: { name: string }) => x.name === CONNECTOR_KEY);

        states.forEach((state) => {
            if (
                state.shapeType === ShapeType.POLYLINE &&
                state.objectType === ObjectType.TRACK &&
                state.label === connectorLabel
            ) {
                const mmsiValue = getMmsiValueFromShape(state);
                if (mmsiValue) {
                    const selectedPoint = getShapeFromStates(ShapeType.POINTS, mmsiValue, states);
                    const selectedRect = getShapeFromStates(ShapeType.RECTANGLE, mmsiValue, states);
                    const connector = state;
                    if (selectedPoint && selectedRect && !selectedRect.outside && !selectedPoint.outside) {
                        updateConnectorHidden(connector, false);
                        updateConnectorPoints(connector, selectedRect, selectedPoint);
                    } else if (!connector.hidden) {
                        updateConnectorHidden(connector, true);
                    }
                }
            }
        });
    };
    useEffect(() => {
        if (!frameWasFetching) {
            // update connectors only after frame finish to fetch the data
            updateAllConnectors();
        }
    }, [frameWasFetching]);
    useEffect(() => {
        // Reset the selection.
        resetSelection();
    }, [frame]);

    /**
     * Set the 'mmsi' field in the rectangle to an empty string, and remove the connector.
     */
    const removeAssociation = (shape: ObjectState): void => {
        updateRectangleAnnotation(shape, '', MMSI_KEY);
        const polylineShape = getConnecterShape(shape, states);
        if (polylineShape) {
            removeObject(polylineShape);
        }
    };

    /**
     * Check if the selected rectangle has another association, and remove it if it exists.
     */
    const checkIfRectangleHasAssociation = (): void => {
        const { selectedRect } = canvasInstance?.selectedShapeState ?? {};
        const mmsiValue = getMmsiValueFromShape(selectedRect);
        if (mmsiValue) {
            removeAssociation(selectedRect);
        }
    };

    /**
     * Value from the mmsi field from the Point (AIS) needs to be
     * copied to the Rectangle mmsi field.
     */
    const updateShapeAttributes = (value: string): void => {
        const { selectedRect } = canvasInstance?.selectedShapeState ?? {};
        updateRectangleAnnotation(selectedRect, value, MMSI_KEY);
    };

    /**
     * Create new association.
     */
    const createAssociation = (): void => {
        const { selectedPoint, selectedRect } = canvasInstance?.selectedShapeState ?? {};
        const pointMmsiValue = getPointValue(selectedPoint, MMSI_KEY);
        if (pointMmsiValue) {
            updateShapeAttributes(pointMmsiValue);
            drawPolylineConnector(selectedRect, selectedPoint);
        }
    };

    /**
     * Check if the selected point has another association, and remove it if it exists.
     */
    const checkIfPointHasAssociation = (): void => {
        const { selectedPoint } = canvasInstance?.selectedShapeState ?? {};
        const pointMmsiValue = getPointValue(selectedPoint, MMSI_KEY);
        const rectangleShape = getShapeFromStates(ShapeType.RECTANGLE, pointMmsiValue, states);
        if (rectangleShape) {
            removeAssociation(rectangleShape);
        }
    };

    /** For orca
     * Check if the selected point or rectangle
     * have another association, and start new association with selected rectangle.
     * Then notify the canvas view about the association status.
     */
    const onAssociate = (): void => {
        checkIfPointHasAssociation();
        checkIfRectangleHasAssociation();
        createAssociation();
        resetSelection();
    };

    return (
        <Button
            id='cvat-annotation-state-btn'
            className='cvat-annotation-header-button cvat-state-btn'
            onClick={onAssociate}
        >
            Link
        </Button>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociationButton);
